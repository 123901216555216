/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

// % protected region % [Override contents here] on begin
export const SERVER_URL = '';
export const BOOKING_ENTITY_URL = `${SERVER_URL}/api/entity/BookingEntity`;
export const FERRY_BOOKING_URL = `${SERVER_URL}/api/FerryBooking`;
export const EVENT_BOOKING_URL = `${SERVER_URL}/api/EventBooking`;
export const ROUTE_ENTITY_URL = `${SERVER_URL}/api/entity/RouteEntity`;
export const LOCATION_ENTITY_URL = `${SERVER_URL}/api/entity/LocationEntity`;
export const PASSENGER_TYPE_ENTITY_URL = `${SERVER_URL}/api/entity/PassengerTypeEntity`;
export const ADDITIONAL_OPTION_ENTITY_URL = `${SERVER_URL}/api/entity/AdditionalBookingOptionEntity`;
export const EVENT_DETAIL_ENTITY_URL = `${SERVER_URL}/api/entity/EventDetailEntity`;
export const TRANSACTION_ENTITY_URL = `${SERVER_URL}/api/entity/TransactionEntity`;
export const LICENSE_PLATE_API = `${SERVER_URL}/api/licenseplate`;
// % protected region % [Override contents here] end

// % protected region % [Override application name here] on begin
export const APPLICATION_NAME = 'Ferryhawk';
// % protected region % [Override application name here] end

// % protected region % [Add any extra constants here] on begin
export const FH_VERSION = '4.2.6.0';
export const SERVER_VERSION_HASH = '08b32d8dfb';
export const FEATURE_IMAGE_1_URL = '/api/entity/ConfigThemeEntity/image/featureImage1';
export const FEATURE_IMAGE_2_URL = '/api/entity/ConfigThemeEntity/image/featureImage2';
export const FEATURE_IMAGE_3_URL = '/api/entity/ConfigThemeEntity/image/featureImage3';
export const FEATURE_IMAGE_4_URL = '/api/entity/ConfigThemeEntity/image/featureImage4';
export const FEATURE_IMAGE_5_URL = '/api/entity/ConfigThemeEntity/image/featureImage5';
export const FEATURE_IMAGE_6_URL = '/api/entity/ConfigThemeEntity/image/featureImage6';

export const VEHICLE_ICON_DARK_URL = '/api/entity/ConfigThemeEntity/image/vehicleIconDark';
export const VEHICLE_ICON_LIGHT_URL = '/api/entity/ConfigThemeEntity/image/vehicleIconLight';

export const LOGO_PRIMARY_DARK = '/api/entity/ConfigThemeEntity/image/logoPrimaryDark';
export const LOGO_PRIMARY_LIGHT = '/api/entity/ConfigThemeEntity/image/logoPrimaryLight';

export const EVENT_IMAGE = '/api/entity/UploadedImageEntity/image/';
export const DEFAULT_COUNTRY_CODE = 'AU';
// % protected region % [Add any extra constants here] end
