import gql from 'graphql-tag';
import React from 'react';

import useCheckInStore from 'Hooks/useCheckInStore';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { BoardingStatus, isBoardingResponse } from 'Models/_HumanWritten/BoardingResponse';
import { store } from 'Models/Store';
import { checkInBoarding, checkInMove } from 'Services/Api/_HumanWritten/CheckInService';
import { getBookedSummaryAttributes } from 'Services/CustomGql/BookingSummaryType';
import alertToast from 'Util/ToastifyUtils';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { showCheckInAlreadyModal } from 'Views/Components/_HumanWritten/Modal/CheckInAlreadyModalContents';
import { showCheckInBookingNotFoundModal } from 'Views/Components/_HumanWritten/Modal/CheckInBookingNotFoundModalContents';
import { showCheckInCancelledBookingModal } from 'Views/Components/_HumanWritten/Modal/CheckInCancelledBookingModalContents';
import { showCheckInDifferentRouteModal } from 'Views/Components/_HumanWritten/Modal/CheckInDifferentRouteModalContents';
import { showCheckInMoveRequestModal } from 'Views/Components/_HumanWritten/Modal/CheckInMoveRequestModalContents';
import { showCheckInNotAFerryBookingModal } from 'Views/Components/_HumanWritten/Modal/CheckInNotAFerryBookingModalContents';
import { showCheckInPastTripModal } from 'Views/Components/_HumanWritten/Modal/CheckInPastTripModalContents';
import { showCheckInScanConfirm } from 'Views/Components/_HumanWritten/Modal/CheckInScanConfirm';
import { CheckInBookingOverviewDto } from '../../FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { CHECK_IN_ALERT_CONFIG } from '../../FerryCheckIn/CheckInUtils';

function getIdFromUrl(url: string) {
	try {
		decodeURI(url);
	} catch (e) {
		console.error(e);
		return;
	}

	const sections = url.split('/');
	const id = sections[sections.length - 1];

	return id;
}

function customAlert(booking: CheckInBookingOverviewDto) {
	alertToast(
		<span>
			<strong>{`${booking.bookedSummary.userFirstName}`}</strong> is checked in
		</span>,
		'success',
		undefined,
		CHECK_IN_ALERT_CONFIG,
	);
}

const confirmPassengers = async (booking: CheckInBookingOverviewDto | null, next: () => Promise<void>) => {
	if (isNullOrUndefined(booking)) {
		// With no info on booking due to failed fetch, what do we do?
		return showCheckInScanConfirm({
			onConfirm: async () => {
				await next();
			},
		});
	}

	const addOns = booking.bookedSummary?.additionalBookingOptions ?? [];

	return showCheckInScanConfirm({
		onConfirm: async () => {
			await next();
		},
		addOnOptions: addOns,
		booking: booking,
	});
};

async function getBooking(id: string): Promise<CheckInBookingOverviewDto | null> {
	try {
		const response = await store.apolloClient.query<{ bookingEntity: CheckInBookingOverviewDto }, { id: string }>({
			query: gql`
				query bookingForPassengerCount($id: ID) {
					# ${new Date()}
					bookingEntity(id: $id) {
						id
						bookedSummary {
							${getBookedSummaryAttributes(true)}
						}
						user {
							firstName
							lastName
						}
					}
				}
			`,
			fetchPolicy: 'network-only',
			variables: { id },
		});

		const booking = new CheckInBookingOverviewDto(response.data.bookingEntity);
		return booking;
	} catch {
		return null;
	}
}

/**
 * Handles the check in process for a scanned QR code.
 */
export const useTicket = () => {
	usePassengerTypes();
	const checkInStore = useCheckInStore();

	const moveToCurrentTrip = async (fetchedBooking: CheckInBookingOverviewDto) => {
		const bookingId = fetchedBooking.id;

		try {
			return await confirmPassengers(fetchedBooking, async () => {
				await checkInStore.getCheckInConnection()
					.ignoreEventMessagesForBooking(bookingId, async () => {
						// We ignore event messages while moving a booking. Otherwise, the socket onBookingAdded
						// handler would add the booking in the CheckInStore as not-checked-in (see
						// FerryCheckInSocket for more details). So, we want to handle it manually.
						await checkInMove(bookingId, checkInStore.ferryTripId);
						await checkInStore.checkInBooking(bookingId, true, {
							triggerAlert: false,
						});
						customAlert(fetchedBooking);
					});
			});
		} catch (e) {
			console.error('Error with moving booking to current trip');
			return alertToast('Error with moving booking to current trip', 'error', undefined, CHECK_IN_ALERT_CONFIG);
		}
	};

	/**
	 * Expected value is similar to:
	 *
	 *     https://beta-fh.moretonislandadventures.com.au/qr-code/booking/6962aeac-23f4-4da1-bc55-9599be0732d2
	 *
	 * Scenarios listed below:
	 *
	 *     Booking belongs to current trip
	 *     Booking already checked-in
	 *     Booking has same route, but belongs to future trip
	 *     Booking has same route, but belongs to past trip
	 *     Booking belongs to different route
	 *     Booking is cancelled
	 *     Booking is not found
	 */
	const process = async (bookingId: string) => {
		if (stringIsEmpty(bookingId)) {
			console.error('Error in CheckInScanner');
			return;
		}

		const booking = checkInStore.bookings.find(x => x.id === bookingId);

		if (isNotNullOrUndefined(booking)) {
			// Booking already checked-in
			if (booking.checkedIn) {
				return showCheckInAlreadyModal();
			}

			// Booking belongs to current trip
			const onConfirm = async () => {
				await checkInStore.checkInBooking(bookingId, true, {
					triggerAlert: false,
				});
				customAlert(booking);
			};
			return confirmPassengers(booking, onConfirm);
		}

		// Check id using server
		const boardingResponse = await checkInBoarding(checkInStore.ferryTripId, bookingId);

		if (isBoardingResponse(boardingResponse.data)) {
			const { status, departureDateTime, checkedIn } = boardingResponse.data;
			switch (status) {
				case BoardingStatus.ALREADY_CHECKED_IN:
					return showCheckInAlreadyModal();
				case BoardingStatus.DIFFERENT_ROUTE:
					return showCheckInDifferentRouteModal();
				case BoardingStatus.CANCELLED:
					return showCheckInCancelledBookingModal();
				case BoardingStatus.NOT_FOUND:
					return showCheckInBookingNotFoundModal();
				case BoardingStatus.NOT_FERRY_BOOKING:
					return showCheckInNotAFerryBookingModal();
				default:
			}

			// At this point, the scenarios remaining is for a valid booking
			const fetchedBooking = await getBooking(bookingId);

			if (isNullOrUndefined(fetchedBooking)) {
				return alertToast('Booking not found', 'error', undefined, CHECK_IN_ALERT_CONFIG);
			}

			switch (status) {
				case BoardingStatus.CHECKED_IN:
					return confirmPassengers(fetchedBooking, async () => {
						try {
							await checkInStore.checkInBooking(bookingId, true, {
								triggerAlert: false,
							});
							customAlert(fetchedBooking);
						} catch (e) {
							alertToast('Something went wrong with check-in process', 'error', 'Error');
						}
					});
				case BoardingStatus.FUTURE_BOOKING:
					return showCheckInMoveRequestModal({
						departureDateTime: new Date(departureDateTime as Date),
						checkedIn: checkedIn,
						//
						// autoClose false prevents modal from closing after completing onConfirm
						// we will close manually in confirmPassengers
						//
						autoClose: false,
						onConfirm: async () => moveToCurrentTrip(fetchedBooking),
					});
				case BoardingStatus.PAST_BOOKING:
					return showCheckInPastTripModal({
						departureDateTime: new Date(departureDateTime as Date),
						checkedIn: checkedIn,
						//
						// autoClose false prevents modal from closing after completing onConfirm
						// we will close manually in confirmPassengers
						//
						autoClose: false,
						onConfirm: async () => moveToCurrentTrip(fetchedBooking),
					});
			}
		}

		throw new Error('Reach end of process function');
	};

	const processTicket = async (url: string) => {
		try {
			await process(getIdFromUrl(url) ?? '');
		} catch (e) {
			console.error('Error while processing in CheckInScanner', e);
			store.modal.hide();
		}
	};

	return { processTicket };
};
